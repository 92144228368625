import * as R from 'ramda'
import * as commands from '../../commands'
import * as processes from '@rushplay/processes'
import * as recompose from 'recompose'
import * as selectors from '../../selectors'

import {PendingTransaction as Component} from './pending-transaction'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

function mapStateToProps(state, props) {
  const paymentsState = R.prop(props.mountPoint, state)

  return {
    transaction: selectors.getPendingTransaction(paymentsState, props),
    cancelling: processes.isRunning(paymentsState.processes, {
      ids: [`cancel-pending-transaction-${props.transactionId}`],
    }),
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onCancel: () =>
        commands.requestTransactionCancellation({
          attributes: props.attributes,
          transactionId: props.transactionId,
        }),
    },
    dispatch
  )
}

const connector = R.compose(
  recompose.setPropTypes({
    transactionId: PropTypes.string,
    attributes: PropTypes.object,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)

export const PendingTransaction = connector(Component)
