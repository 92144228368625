import PropTypes from 'prop-types'
import {useTransaction} from './use-transaction'

/**
 * @typedef {Object} TransactionRenderProps
 * @property {("deposit"|"withdraw")} type Type of transaction to perform
 */

export function Transaction(props) {
  const transaction = useTransaction({
    amountCents: props.amountCents,
    attributes: props.attributes,
    method: props.method,
    mountPoint: props.mountPoint,
    paymentMethodId: props.paymentMethodId,
  })

  return props.children(transaction)
}

Transaction.propTypes = {
  amountCents: PropTypes.number,
  attributes: PropTypes.object,
  method: PropTypes.oneOf(['process', 'validate']),
  mountPoint: PropTypes.string,
  paymentMethodId: PropTypes.string.isRequired,
}
