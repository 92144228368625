import * as Processes from '@rushplay/processes'
import * as R from 'ramda'

import {BackendType, TransactionState, TransactionType} from '../constants'
import {
  completeTransaction,
  startTransaction,
  updatePaymentMethods,
} from '../actions'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'
import {createRequest} from '../request'

function transformLimit(value) {
  if (value == null) {
    return null
  }

  const normalizedValue = value / 100

  return normalizedValue.toFixed(2)
}

function transformPaymentMethods(body, txType) {
  if (txType === TransactionType.AUTH) {
    return [
      {
        accounts: [],
        backendType: BackendType.PROJS,
        canAddAccount: true,
        id: createPaymentMethodId({
          backendType: BackendType.PROJS,
          providerType: 'bankid',
          transactionType: TransactionType.AUTH,
        }),
        limit: {
          max: null,
          min: null,
        },
        providerType: 'bankid',
        transactionType: TransactionType.AUTH,
      },
    ]
  }
  return R.reduce(
    (methods, method) => {
      const transactionType = method.transaction_type

      if (transactionType === txType) {
        const backendType = BackendType.PROJS

        return R.append(
          {
            accounts: [],
            backendType,
            canAddAccount: true,
            id: createPaymentMethodId({
              backendType,
              providerType: method.provider,
              transactionType,
            }),
            limit: {
              max:
                txType === TransactionType.AUTH
                  ? null
                  : transformLimit(method.limits.max_cents),
              min:
                txType === TransactionType.AUTH
                  ? null
                  : transformLimit(method.limits.min_cents),
            },
            providerType: method.provider,
            transactionType,
          },
          methods
        )
      }

      return methods
    },
    [],
    body.result
  )
}

/**
 * Fetches user’s payment methods.
 *
 * @param {Object} params
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethods(params) {
  const processId = `projs/fetch-payments-methods-by-user`
  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PROJS,
        requestMethod: 'GET',
        urlTemplate: '/payment_methods',
      }),
      (res) => [
        updatePaymentMethods(
          transformPaymentMethods(res.value, params.transactionType)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          updatePaymentMethods(
            new Error('errors.paymentiq.fetch-payment-methods-by-user-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

function transformRedirectOutput(transactionParams, body) {
  if (!body.success) {
    return new Error('errors.payments.perform-transaction-failure')
  }

  return {
    container: 'projs',
    parameters: {
      orderId: body.result.order_id,
      phoneNumber: transactionParams.phone_number,
    },
  }
}

/**
 * Start payment transaction
 *
 * @param {Object} params
 * @param {string} params.appId
 * @param {ProjsExtraAttributes} [params.attributes]
 * @param {number} params.amount
 * @param {string} params.currency
 * @param {string} params.locale
 * @param {string} params.providerType
 * @param {PaymentMethodId} params.paymentMethodId
 * @param {SessionId} params.sessionId
 * @param {TransactionType} params.transactionType
 */
export function performTransaction(params, transactionParams) {
  const language = params.language
    ? params.language
    : R.replace(/_.+$/, '', params.locale)
  const processId = `perform-transaction-${params.paymentMethodId}`

  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PROJS,
        bodyTemplate: {
          order: {
            amount_cents: false,
            app_id: false,
            bank_id_type: false,
            currency: false,
            extra_attributes: {
              affiliate_click_id: false,
              brand_key: false,
              client_type: false,
              deposit_offer_package_id: false,
              language: false,
              netreferer_btag: false,
              session_token: false,
              utm_campaign: false,
              utm_medium: false,
              utm_source: false,
            },
            language: false,
            order_type: false,
            payment_method: false,
            personal_number: false,
          },
        },
        requestData: {
          order: R.merge(transactionParams, {
            amount_cents:
              params.transactionType === TransactionType.AUTH
                ? null
                : params.amountCents,
            app_id: params.appId,
            currency: params.currency,
            extra_attributes: R.merge(params.attributes, {
              language: params.language || params.locale,
              session_token: params.sessionId,
            }),
            language,
            order_type:
              params.transactionType === TransactionType.AUTH
                ? 'signin'
                : params.transactionType,
            payment_method: params.providerType,
          }),
        },
        requestMethod: 'POST',
        urlTemplate: '/orders',
      }),
      (res) => {
        const redirectOutput = transformRedirectOutput(
          transactionParams,
          res.value
        )

        return [
          Processes.stop(processId),
          redirectOutput instanceof Error
            ? completeTransaction(redirectOutput)
            : startTransaction({
                redirectOutput,
                transactionState: TransactionState.WAITING_INPUT,
              }),
        ]
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Processes.stop(processId),
          completeTransaction(
            new Error('errors.paymentiq.perform-transaction-failure')
          ),
        ]
      }
    ),
  ]
}
