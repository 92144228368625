export const PAYMENT_METHODS_UPDATE_REQUESTED =
  'payments/PAYMENT_METHODS_UPDATE_REQUESTED'
export const PLAYER_TRANSACTIONS_UPDATE_REQUESTED =
  'payments/PLAYER_TRANSACTIONS_UPDATE_REQUESTED'
export const TRANSACTION_CANCELLATION_REQUESTED =
  'payments/TRANSACTION_CANCELLATION_REQUESTED'
export const TRANSACTION_START_REQUESTED =
  'payments/TRANSACTION_START_REQUESTED'
export const TRANSACTION_UPDATE_REQUESTED =
  'payments/TRANSACTION_UPDATE_REQUESTED'
export const TRANSACTION_VALIDATION_REQUESTED =
  'payments/TRANSACTION_VALIDATION_REQUESTED'

/**
 * Command to cancel payment transaction
 * @param {Object} params
 * @returns {ReduxAction}
 */
export function requestTransactionCancellation(params) {
  return {
    type: TRANSACTION_CANCELLATION_REQUESTED,
    payload: {
      attributes: params.attributes,
      backendType: params.backendType,
      transactionId: params.transactionId,
    },
  }
}

/**
 * Command to start payment transaction
 * @param {Object} params
 * @param {number} params.amountCents Transaction amount (in cents)
 * @param {PiqExtraAttributes|ProjsExtraAttributes} [params.attributes]
 * @param {PaymentMethodId} params.paymentMethodId
 * @param {Object} [params.transactionData]
 */
export function requestTransactionStart(params) {
  return {
    type: TRANSACTION_START_REQUESTED,
    payload: {
      amountCents: params.amountCents,
      attributes: params.attributes,
      paymentMethodId: params.paymentMethodId,
      transactionData: params.transactionData,
    },
  }
}

/**
 * Command to update payment transaction
 * @param {Object} params
 * @param {string} params.transactionId Transaction ID
 * @param {PaymentMethodId} params.paymentMethodId
 */
export function requestTransactionUpdate(params) {
  return {
    type: TRANSACTION_UPDATE_REQUESTED,
    payload: {
      transactionId: params.transactionId,
      paymentMethodId: params.paymentMethodId,
    },
  }
}

/**
 * Command to validate payment transaction
 * @param {Object} params
 * @param {number} params.amountCents Transaction amount (in cents)
 * @param {PiqExtraAttributes|ProjsExtraAttributes} [params.attributes]
 * @param {PaymentMethodId} params.paymentMethodId
 * @param {Object} [params.transactionData]
 */
export function requestTransactionValidation(params) {
  return {
    type: TRANSACTION_VALIDATION_REQUESTED,
    payload: {
      amountCents: params.amountCents,
      attributes: params.attributes,
      paymentMethodId: params.paymentMethodId,
      transactionData: params.transactionData,
    },
  }
}

export function requestPaymentMethodsUpdate(params) {
  return {
    type: PAYMENT_METHODS_UPDATE_REQUESTED,
    payload: {
      attributes: params.attributes,
      channelId: params.channelId,
      showExpired: params.showExpired,
      showNew: params.showNew,
      transactionType: params.transactionType,
    },
  }
}

/**
 * Command to fetch pending transactions
 * @param {Object} params
 * @param {PiqExtraAttributes|ProjsExtraAttributes} [params.attributes]
 * @param {Date} [params.maxDate] Filter transactions created before given date
 * @param {Date} [params.minDate] Filter transactions created after given date
 * @param {PaymentMethodId} [params.paymentMethodId]
 *   Filter transaction by payment method
 * @param {Array.<String>} [params.states]
 *   Comma-separated list of transaction states to filter by
 * @param {string} [params.transactionId] Filter by transaction ID
 * @param {TransactionType} [params.transactionType]
 *   Filter by transaction type
 */
export function requestPlayerTransactionsUpdate(params) {
  return {
    type: PLAYER_TRANSACTIONS_UPDATE_REQUESTED,
    payload: {
      attributes: params.attributes,
      maxDate: params.maxDate,
      minDate: params.minDate,
      paymentMethodId: params.paymentMethodId,
      states: params.states,
      transactionId: params.transactionId,
      transactionType: params.transactionType,
    },
  }
}
