import * as PaymentPropTypes from '../../prop-types'
import * as R from 'ramda'
import * as actions from '../../actions'
import * as commands from '../../commands'
import * as recompose from 'recompose'
import * as selectors from '../../selectors'

import PropTypes from 'prop-types'
import {TransactionProviderBase} from './transaction-provider-base'
import {connect} from 'react-redux'

function makeMapStateToProps() {
  const getSubstate = selectors.createSubstateSelector()
  return function mapStateToProps(state, props) {
    const substate = getSubstate(state, props)
    return {
      transactionId: selectors.getTransactionId(substate),
      redirectParams: selectors.getRedirectParams(substate),
      transactionState: selectors.getTransactionState(substate),
      paymentMethodId: selectors.getActiveTransactionPaymentId(substate),
    }
  }
}

function makeMapDispatchToProps() {
  const dependencies = {}
  const memoizedActions = {}

  return function mapDispatchToProps(dispatch, props) {
    function isStale(key) {
      const initialized = R.has(key, dependencies)
      const changed = R.not(R.equals(dependencies[key], props[key]))

      const stale = !initialized || changed

      if (stale) {
        dependencies[key] = props[key]
      }

      return stale
    }

    function onCleanup() {
      dispatch(actions.clear())
    }

    function onInit() {
      dispatch(
        commands.requestPaymentMethodsUpdate({
          transactionType: props.transactionType,
        })
      )
    }

    if (isStale('attributes')) {
      memoizedActions.onPerform = function onPerform(
        paymentMethodId,
        amountCents,
        transactionData
      ) {
        dispatch(
          commands.requestTransactionStart({
            amountCents,
            attributes: props.attributes,
            paymentMethodId,
            transactionData,
          })
        )
      }

      memoizedActions.onValidate = function onValidate(
        paymentMethodId,
        amountCents,
        transactionData
      ) {
        dispatch(
          commands.requestTransactionValidation({
            amountCents,
            attributes: props.attributes,
            paymentMethodId,
            transactionData,
          })
        )
      }
    }

    if (isStale('transactionType')) {
      memoizedActions.onCleanup =
        process.env.NODE_ENV === 'test'
          ? props.onCleanup || onCleanup
          : onCleanup

      memoizedActions.onInit =
        process.env.NODE_ENV === 'test' ? props.onInit || onInit : onInit
    }

    if (isStale('onCancel')) {
      memoizedActions.onCancel = function onCancel() {
        dispatch(actions.resetTransaction())
        if (props.onCancel) {
          props.onCancel()
        }
      }
    }

    if (isStale('onFailure')) {
      memoizedActions.onFailure = function onFailure() {
        if (props.onFailure) {
          props.onFailure()
        }
      }
    }

    if (isStale('onReset')) {
      memoizedActions.onReset = function onReset() {
        dispatch(actions.resetTransaction())
        if (props.onReset) {
          props.onReset()
        }
      }
    }

    if (isStale('onSuccess')) {
      memoizedActions.onSuccess = function onSuccess() {
        dispatch(actions.completeTransaction())
        if (props.onSuccess) {
          props.onSuccess()
        }
      }
    }

    // We use same `memoizedActions` object and only update its values, thus we
    // must pick values one by one to avoid false-positive shallow equality
    return {
      onCancel: memoizedActions.onCancel,
      onCleanup: memoizedActions.onCleanup,
      onFailure: memoizedActions.onFailure,
      onInit: memoizedActions.onInit,
      onPerform: memoizedActions.onPerform,
      onReset: memoizedActions.onReset,
      onSuccess: memoizedActions.onSuccess,
      onValidate: memoizedActions.onValidate,
    }
  }
}

const connector = R.compose(
  recompose.setPropTypes({
    mountPoint: PropTypes.string,
    transactionType: PaymentPropTypes.transactionType,
    onFailure: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
  }),
  connect(makeMapStateToProps, makeMapDispatchToProps)
)

export const TransactionProvider = connector(TransactionProviderBase)
export {useTransactionContext} from './transaction-provider-base'
