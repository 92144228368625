import * as R from 'ramda'
import * as api from '@rushplay/api-client'
import * as processes from '@rushplay/processes'

import {updateReceipts, updateTotalDepositsCents} from './actions'

/**
 * Fetches users transactions from given page number.
 *
 * @param {ReceiptMetaData["page"]} page
 * @returns Redux Effects fetch action
 */
export function fetchReceipts(page) {
  return [
    processes.start(`fetch-receipts-${page}`),
    api.fetchReceipts(page, {
      success: (res) => [
        processes.stop(`fetch-receipts-${page}`),
        updateReceipts(res.value),
      ],
      failure: () => [
        processes.stop(`fetch-receipts-${page}`),
        updateReceipts(new Error('errors.paymentiq.fetch-receipts-failure')),
      ],
      version: 1,
    }),
    api.fetchTotalDepositsCents({
      success: (res) =>
        updateTotalDepositsCents(
          R.path(['result', 'total_deposit_cents'], res.value)
        ),
      version: 2,
    }),
  ]
}
