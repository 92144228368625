import * as Reselect from 'reselect'

// This file contains the redux boilerplate for "data" stuff
// This data is additional data that isn't directly needed for a successful transaction
// Used to provide a better user experience by prefilling inputfields with user data
// and handles tracking data. Both from and to the host application

/**
 * Empty immutable object
 * Used for selector fallbacks
 * @constant {Object}
 */
const EMPTY_OBJECT = Object.freeze({})

/**
 * Action-type
 * @constant {string}
 */
export const INITIATED = 'payer/INITIATED'
/**
 * Action-type
 * @constant {string}
 */
export const STEP_CHANGED = 'payer/STEP_CHANGED'
/**
 * Action-type
 * @constant {string}
 */
export const PAYMENT_METHOD_SELECTED = 'payer/PAYMENT_METHOD_SELECTED'
/**
 * Action-type
 * @constant {string}
 */
export const CRYPTO_CURRENCY_SELECTED = 'payer/CRYPTO_CURRENCY_SELECTED'
/**
 * Action-type
 * @constant {string}
 */
export const TRANSACTION_CANCELED = 'payer/TRANSACTION_CANCELED'
/**
 * Action-type
 * @constant {string}
 */
export const TRANSACTION_FAILED = 'payer/TRANSACTION_FAILED'
/**
 * Action-type
 * @constant {string}
 */
export const TRANSACTION_STARTED = 'payer/TRANSACTION_STARTED'
/**
 * Action-type
 * @constant {string}
 */
export const TRANSACTION_SUCCEEDED = 'payer/TRANSACTION_SUCCEEDED'
/**
 * Action-type
 * @constant {string}
 */
export const UPDATE_INFORMATION = 'payer/UPDATE_INFORMATION'
/**
 * Action-type
 * @constant {string}
 */
export const UPDATE_FORWARDING_DATA = 'payer/UPDATE_FORWARDING_DATA'
/**
 * Action-type
 * @constant {string}
 */
export const NEW_VERSION = 'payer/NEW_VERSION'

/**
 * Used to tell host app that we're ready to recieve data
 * and can recieve data
 * @returns {Object} Redux action
 */
export function initiated() {
  return {type: INITIATED}
}

/**
 * Used to tell host app that we have new version of payer
 * @returns {Object} Redux action
 */
export function toggleNewVersion() {
  return {type: NEW_VERSION}
}

/**
 * Used to tell host-app what the current step is
 * @param {string} payload Name of current step
 * @returns {Object} Redux action
 */
export function stepChanged(payload) {
  return {
    type: STEP_CHANGED,
    payload,
  }
}

/**
 * Used to tell host-app what the selected payment method is
 * @param {string} payload payment provider method
 * @returns {Object} Redux action
 */
export function paymentMethodSelected(payload) {
  return {
    type: PAYMENT_METHOD_SELECTED,
    payload,
  }
}

/**
 * Used to tell host-app what the selected crypto currency is
 * @param {string} payload crypto currency
 * @returns {Object} Redux action
 */
export function cryptoCurrencySelected(payload) {
  return {
    type: CRYPTO_CURRENCY_SELECTED,
    payload,
  }
}

/**
 * Updates the data that will be forwarded to host app in success/failure
 * @param {Object} payload Can be any data
 * @returns {Object} Redux action
 */
export function updateForwardingData(payload) {
  return {
    type: UPDATE_FORWARDING_DATA,
    payload,
  }
}

/**
 * Used to tell host app that user canceled their transaction
 * @returns {Object} Redux action
 */
export function transactionCancel() {
  return {type: TRANSACTION_CANCELED}
}

/**
 * Used to tell host app that the transaction failed
 * @param {Object} payload error
 * @returns {Object} Redux action
 */
export function transactionFailure(payload) {
  return {
    type: TRANSACTION_FAILED,
    payload,
  }
}

/**
 * Used to tell host app that the transaction has started
 * @param {Object} payload contains provider's URL
 * @param {String} payload.url
 * @returns {Object} Redux action
 */
export function transactionStart({url}) {
  return {
    type: TRANSACTION_STARTED,
    payload: {url},
  }
}

/**
 * Used to tell host app that the transaction succeeded
 * @param {Object} payload usually contains a session-token
 * @returns {Object} Redux action
 */
export function transactionSuccess(payload) {
  return {
    type: TRANSACTION_SUCCEEDED,
    payload,
  }
}

/**
 * Updates store with data about the user
 * @param {Object} User data from host app
 * @param {string=} affiliateClickId Used for tracking
 * @param {string=} city Users city
 * @param {string=} email Users email
 * @param {string=} firstName Users first name
 * @param {string=} lastName Users last name
 * @param {string=} netrefererBtag Btag
 * @param {string=} ssn Users Social security number (Personal number)
 * @param {string=} phoneNumber Users phone-number with or without countrycode (+XX)
 * @param {string=} postalCode Users postalCode / zipCode
 * @param {string=} province Users province
 * @param {string=} state Users state
 * @param {string=} street Users street
 * @param {string=} utmCampaign Used for tracking
 * @param {string=} utmMedium Used for tracking
 * @param {string=} utmSource Used for tracking
 * @returns {Object} Redux action
 */
export function updateInformation({
  affiliateClickId,
  city,
  email,
  firstName,
  lastName,
  netrefererBtag,
  phoneNumber,
  postalCode,
  province,
  ssn,
  state,
  street,
  utmCampaign,
  utmMedium,
  utmSource,
  verificationToken,
}) {
  return {
    type: UPDATE_INFORMATION,
    payload: {
      affiliateClickId,
      city,
      email,
      firstName,
      lastName,
      netrefererBtag,
      phoneNumber,
      postalCode,
      province,
      ssn,
      state,
      street,
      utmCampaign,
      utmMedium,
      utmSource,
      verificationToken,
    },
  }
}

/**
 * Helper function to get correct state
 * @param {Object} Redux state
 * @returns {Object} data state
 */
function getData(state) {
  return state.data
}

/**
 * Get the data to forward to host app on transaction end
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getForwardData = Reselect.createSelector(
  getData,
  (data) => data.forwardingData || EMPTY_OBJECT
)

/**
 * Get affiliate click id
 * @param {Object} Redux state
 * @returns {string}
 */
export const getAffiliateClickId = Reselect.createSelector(
  getData,
  (data) => data.affiliateClickId
)

/**
 * Gets users city
 * @param {Object} Redux state
 * @returns {string}
 */
export const getCity = Reselect.createSelector(getData, (data) => data.city)

/**
 * Gets users email
 * @param {Object} Redux state
 * @returns {string}
 */
export const getEmail = Reselect.createSelector(getData, (data) => data.email)

/**
 * Gets users first name
 * @param {Object} Redux state
 * @returns {string}
 */
export const getFirstName = Reselect.createSelector(
  getData,
  (data) => data.firstName
)

/**
 * Gets users last name
 * @param {Object} Redux state
 * @returns {string}
 */
export const getLastName = Reselect.createSelector(
  getData,
  (data) => data.lastName
)

/**
 * Gets users full name - Usually used for creditcards
 * @param {Object} Redux state
 * @returns {string}
 */
export const getFullName = Reselect.createSelector(
  [getFirstName, getLastName],
  (firstName, lastName) =>
    firstName && lastName ? `${firstName} ${lastName}` : ''
)

/**
 * Gets users netreferer btag
 * @param {Object} Redux state
 * @returns {string}
 */
export const getNetrefererBtag = Reselect.createSelector(
  getData,
  (data) => data.netrefererBtag
)

/**
 * Gets users phonenumber (with our without leading countrycode-number +XX)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getPhoneNumber = Reselect.createSelector(
  getData,
  (data) => data.phoneNumber
)

/**
 * Gets users postalcode/zip
 * @param {Object} Redux state
 * @returns {string}
 */
export const getPostalCode = Reselect.createSelector(
  getData,
  (data) => data.postalCode
)

/**
 * Gets users province
 * @param {Object} Redux state
 * @returns {string}
 */
export const getProvince = Reselect.createSelector(
  getData,
  (data) => data.province
)

/**
 * Gets users social security number (personal number)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getSsn = Reselect.createSelector(getData, (data) => data.ssn)

/**
 * Gets users state
 * @param {Object} Redux state
 * @returns {string}
 */
export const getState = Reselect.createSelector(getData, (data) => data.state)

/**
 * Gets users street address
 * @param {Object} Redux state
 * @returns {string}
 */
export const getStreet = Reselect.createSelector(getData, (data) => data.street)

/**
 * Gets users utm-campaign tracking data
 * @param {Object} Redux state
 * @returns {string}
 */
export const getUtmCampaign = Reselect.createSelector(
  getData,
  (data) => data.utmCampaign
)

/**
 * Gets users utm-medium tracking data
 * @param {Object} Redux state
 * @returns {string}
 */
export const getUtmMedium = Reselect.createSelector(
  getData,
  (data) => data.utmMedium
)

/**
 * Gets users utm-source tracking data
 * @param {Object} Redux state
 * @returns {string}
 */
export const getUtmSource = Reselect.createSelector(
  getData,
  (data) => data.utmSource
)

/**
 * Gets users verification token (usually just the session-token)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getVerificationToken = Reselect.createSelector(
  getData,
  (data) => data.verificationToken
)

/**
 * Data Reducer
 * @param {Object} state Redux state
 * @param {Object} action Redux action
 * @returns {Object} state management
 */
export function reducer(state = EMPTY_OBJECT, action) {
  switch (action.type) {
    case UPDATE_INFORMATION: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case UPDATE_FORWARDING_DATA: {
      return {
        ...state,
        forwardingData: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
