import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import PropTypes from 'prop-types'

/**
 * Router Provider
 * @component Router
 * @params {Object} props Component props
 * @params {Object} props.context Router context
 * @returns {ReactNode} Router based on browser or server
 */
export function Router(props) {
  if (process.browser) {
    return <ReactRouter.BrowserRouter {...props} />
  }

  return <ReactRouter.StaticRouter {...props} />
}

Router.defaultProps = {
  context: {},
}

Router.propTypes = {
  context: PropTypes.object,
}
