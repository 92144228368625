import PropTypes from 'prop-types'
import {useEffect} from 'react'

export function Receipts(props) {
  useEffect(() => {
    props.onFetch(props.currentPage)
  }, [props.currentPage])

  return props.children({
    receipts: props.receipts,
    loading: props.loading,
    totalPages: props.totalPages,
  })
}

Receipts.propTypes = {
  currentPage: PropTypes.number,
  children: PropTypes.func,
  loading: PropTypes.bool,
  receipts: PropTypes.array,
  totalPages: PropTypes.number,
  onFetch: PropTypes.func,
}
