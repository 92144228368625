import * as R from 'ramda'
import * as selectors from '../../selectors'

import React from 'react'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'
import {useTransactionContext} from '../transaction-provider'

export function usePaymentMethod(id) {
  const transaction = useTransactionContext()

  const getSubstate = React.useCallback(
    (state) => R.prop(transaction.mountPoint, state),
    [transaction.mountPoint]
  )

  const getPaymentMethod = React.useCallback(
    (state) => selectors.getPaymentMethod(state, {id}),
    [id]
  )

  const getData = React.useMemo(
    () => createSelector([getSubstate], getPaymentMethod),
    [getSubstate, getPaymentMethod]
  )

  return useSelector(getData)
}
