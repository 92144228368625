import PropTypes from 'prop-types'
import {usePaymentMethods} from './use-payment-methods'

/**
 * @typedef {Object} PaymentMethodsRenderProps
 * @property {Array.<Object>} paymentMethods List of payment method IDs
 */

export function PaymentMethods(props) {
  if (process.env.NODE_ENV) {
    // eslint-disable-next-line no-console
    console.warn(
      'DEPRECATED',
      '<PaymentMethods> component is deprecated in favor of `usePaymentMethods` hook'
    )
  }
  const paymentMethods = usePaymentMethods(props.mountPoint)
  return props.children(paymentMethods)
}

PaymentMethods.propTypes = {
  children: PropTypes.func,
  mountPoint: PropTypes.string,
}
