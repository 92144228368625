import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as Utils from './utils'

import {
  completeTransaction,
  removePendingTransaction,
  startTransaction,
  updateAccounts,
  updatePaymentMethods,
  updatePendingTransactions,
  updateTransaction,
} from '../actions'

import {BackendType} from '../constants'
import {bind} from 'redux-effects'
import camelcase from 'camelcase'
import {createRequest} from '../request'
import {format} from 'date-fns'
import paymentMethodParams from './payment-method-params'

/**
 * Convert attributes to format expected by PaymentIQ
 *
 * { user_id: '1000' } -> { 'attributes[user_id]': '1000' }
 *
 * @param {Object} attributes
 * @returns {Object}
 */
function toQueryStringAttributes(attributes) {
  const result = {}
  for (const key in attributes) {
    result[`attributes[${key}]`] = attributes[key]
  }
  return result
}

/**
 * Convert attributes to format expected by PaymentIQ
 *
 * { user_id: '1000' } -> { userId: '1000' }
 *
 * @param {Object} attributes
 * @returns {Object}
 */
function toBodyAttributes(attributes) {
  const result = {}
  for (const key in attributes) {
    result[camelcase(key)] = attributes[key]
  }
  return result
}

function toError(body) {
  if (body.txState === 'FAILED') {
    const message = R.pathOr(
      'errors.transaction.unknown',
      ['errors', '0', 'keys'],
      body
    )
    return new Error(message)
  }
}

/**
 * Prepare transaction parameters to satisfy PIQ API requirements
 * @param {*} transactionParams
 */
function toTransactionParams(transactionParams) {
  // empty account ID means new account; must not be sent to PIQ
  if (transactionParams.accountId === '') {
    return R.omit(['accountId'], transactionParams)
  }

  return transactionParams
}

/**
 * Request cancel user's withdrawal.
 *
 * @param {Object} params
 * @param {MerchantId} params.merchantId
 * @param {SessionId} params.sessionId
 * @param {string} params.transactionId
 * @param {UserId} params.userId
 * @returns Redux Effects fetch action
 */
export function cancelPendingTransaction(params) {
  const processId = `cancel-pending-transaction-${params.transactionId}`
  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PIQ,
        requestData: {
          merchantId: params.merchantId,
          sessionId: params.sessionId,
          transactionId: params.transactionId,
          userId: params.userId,
        },
        requestMethod: 'DELETE',
        urlTemplate:
          '/api/user/transaction{/merchantId,userId,transactionId}{?sessionId,attributes,locale}',
      }),
      (res) => [
        removePendingTransaction(res.value.transactionId),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Processes.stop(processId),
          removePendingTransaction(
            new Error('errors.paymentiq.cancel-pending-transaction-failure')
          ),
        ]
      }
    ),
  ]
}

/**
 * Update player’s payment methods
 *
 * @param {Object} params
 * @param {PiqExtraAttributes} [params.attributes]
 * @param {MerchantId} [params.merchantId]
 * @param {string} [params.channelId] Channel ID used for method
 * @param {string} [params.locale] Locale used for payment method
 * @param {SessionId} [params.sessionId]
 * @param {boolean} [params.showExpired] Include expired accounts
 * @param {boolean} [params.showNew] Include new accounts
 * @param {TransactionType} params.transactionType
 * @param {UserId} [params.userId]
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethodsByUser(params) {
  const processId = `piq/fetch-payments-methods-by-user`
  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PIQ,
        requestData: {
          attributes: toQueryStringAttributes(params.attributes),
          channelId: params.channelId,
          locale: params.locale,
          method: params.transactionType,
          merchantId: params.merchantId,
          sessionId: params.sessionId,
          showExpired: params.showExpired,
          showNew: params.showNew,
          userId: params.userId,
        },
        requestMethod: 'GET',
        urlTemplate:
          '/api/user/payment/method{/merchantId,userId}{?sessionId,method,channelId,showExpired,showNew,locale,attributes*}',
      }),
      (res) => [
        updatePaymentMethods(Utils.getPaymentMethods(res.value)),
        updateAccounts(Utils.getAccountsFromPaymentMethods(res.value)),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          updatePaymentMethods(
            new Error('errors.paymentiq.fetch-payment-methods-by-user-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

function transformPendingTransaction(transactions) {
  return R.map(R.assoc('backendType', BackendType.PIQ), transactions)
}

/**
 * Fetches user’s pending transactions.
 *
 * @param {Object} params
 * @param {string} [params.locale]
 *   User’s locale; used for communications (e.g. error messages)
 * @param {Date} [params.maxDate] Filter transactions created before given date
 * @param {MerchantId} params.merchantId
 * @param {Date} [params.minDate] Filter transactions created after given date
 * @param {PaymentMethodId} [params.paymentMethodId]
 *   Filter transaction by payment method
 * @param {SessionId} params.sessionId
 * @param {Array.<String>} [params.states]
 *   Comma-separated list of transaction states to filter by
 * @param {string} [params.transactionId] Filter by transaction ID
 * @param {TransactionType} [params.transactionType]
 *   Filter by transaction type
 * @param {UserId} params.userId
 * @returns Redux Effects fetch action
 */
export function fetchPendingTransactions(params) {
  const processId = `piq/fetch-pending-transactions`
  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PIQ,
        requestData: {
          locale: params.locale,
          maxDate:
            params.maxDate && format(params.maxDate, 'YYYY-MM-DD HH:mm:ss'),
          merchantId: params.merchantId,
          method: params.transactionType,
          minDate:
            params.minDate && format(params.minDate, 'YYYY-MM-DD HH:mm:ss'),
          sessionId: params.sessionId,
          states: params.states,
          transactionId: params.transactionId,
          txType:
            params.paymentMethodId && params.paymentMethodId.replace('Piq', ''),
          userId: params.userId,
        },
        requestMethod: 'GET',
        urlTemplate:
          '/api/user/transaction{/merchantId,userId}{?sessionId,states,txType,maxDate,minDate,transactionId,method,locale}',
      }),
      (res) => [
        updatePendingTransactions(
          transformPendingTransaction(Utils.getPendingTransactions(res.value))
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          updatePendingTransactions(
            new Error('errors.paymentiq.fetch-pending-transactions-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Performs transaction.
 *
 * @param {Object} params
 * @param {number} params.amount Amount of the transaction in cents
 * @param {PiqExtraAttributes} [params.attributes]
 * @param {MerchantId} [params.merchantId]
 * @param {("process"|"validate")} [params.method="process"]
 *   Method to execute. You can either process a payment or validate a payment
 *   with a server-side validation.
 * @param {string} [params.locale]
 *   User’s locale; used for communications (e.g. error messages)
 * @param {PaymentMethodId} params.paymentMethodId
 * @param {string} params.providerType Provider ID to perform payment with
 * @param {SessionId} [params.sessionId]
 * @param {TransactionType} params.transactionType Transaction type to perform
 * @param {UserId} [params.userId]
 * @param {TransactionParameters} [transactionParams]
 * @returns Redux Effects fetch action
 */
export function performTransaction(params, transactionParams) {
  const additionalBodyTemplate = R.pathOr(
    {},
    [R.toLower(params.providerType), R.toLower(params.transactionType)],
    paymentMethodParams
  )

  const processId = `perform-transaction-${params.paymentMethodId}`
  return [
    Processes.start(processId),
    bind(
      createRequest({
        backendType: BackendType.PIQ,
        bodyTemplate: R.mergeDeepRight(additionalBodyTemplate, {
          amount: true,
          attributes: toBodyAttributes({
            affiliate_click_id: false,
            cancel_url: false,
            channel_id: false,
            deposit_offer_package_id: false,
            failure_url: false,
            seon_session: false,
            netreferer_btag: false,
            pending_url: false,
            success_url: false,
            utm_campaign: false,
            utm_medium: false,
            utm_source: false,
          }),
          merchantId: true,
          sessionId: false,
          userId: false,
        }),
        requestData: toTransactionParams(
          R.merge(transactionParams, {
            amount:
              params.currency === 'UBT'
                ? params.amountCents / 100000000
                : params.amountCents / 100,
            attributes: toBodyAttributes(params.attributes),
            locale: Utils.toNormalizedLocale(
              params.paymentMethodId,
              params.locale,
              params.language,
              params.countryCode
            ),
            merchantId: params.merchantId,
            method: params.method,
            provider: R.toLower(params.providerType),
            sessionId: params.sessionId,
            type: params.transactionType,
            userId: params.userId,
          })
        ),
        requestMethod: 'POST',
        urlTemplate: '/api{/provider,type,method}{?locale}',
      }),
      (res) => {
        const redirectOutput = R.pathOr({}, ['redirectOutput'], res.value)

        return [
          R.isEmpty(redirectOutput)
            ? completeTransaction(toError(res.value))
            : startTransaction({
                redirectOutput,
                transactionId: R.last(R.split('A', res.value.txRefId)),
                transactionState: res.value.txState,
                paymentMethodId: params.paymentMethodId,
              }),
          Processes.stop(processId),
        ]
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          completeTransaction(
            new Error('errors.paymentiq.perform-transaction-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Request transaction state update
 *
 * @param {Object} params
 * @param {MerchantId} params.merchantId
 * @param {SessionId} params.sessionId
 * @param {string} params.transactionId
 * @param {UserId} params.userId
 * @returns Redux Effects fetch action
 */
export function fetchTransactionState(params) {
  return [
    bind(
      createRequest({
        backendType: BackendType.PIQ,
        requestData: {
          merchantId: params.merchantId,
          sessionId: params.sessionId,
          transactionId: params.transactionId,
          userId: params.userId,
        },
        requestMethod: 'GET',
        urlTemplate:
          '/api/user/transaction{/merchantId,userId,transactionId}/status{?sessionId}',
      }),
      (res) =>
        updateTransaction({
          transactionState: res.value.txState,
        }),
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }
      }
    ),
  ]
}
