import PropTypes from 'prop-types'
import {useEffect} from 'react'

/**
 * @typedef {Object} PendingTransactionsRenderProps
 * @property {Array.<Object>} pendingTransactions List of pending transaction IDs
 */

export function PendingTransactions(props) {
  useEffect(() => {
    props.onFetch()
  }, [])
  return props.children(props.pendingTransactions)
}

PendingTransactions.propTypes = {
  children: PropTypes.func,
  transactionType: PropTypes.oneOf(['deposit', 'withdrawal']),
  onFetch: PropTypes.func,
}
