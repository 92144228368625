import * as Configuration from './configuration'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Suspense from './suspense'

import {lazy as loadable} from '@loadable/component'

const Callback = loadable(() => import('./callback'))
const Transaction = loadable(() => import('./transaction'))

/**
 * Applications heart
 * @component App
 * @returns {ReactNode} Component that wraps application routes with suspense boundary
 */
export function App() {
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(Configuration.updateDevicePixelRatio(window.devicePixelRatio))
  }, [dispatch])

  return (
    <Suspense.Boundary>
      <ReactRouter.Switch>
        <ReactRouter.Route exact path="/callbacks/:callback">
          <Callback />
        </ReactRouter.Route>
        <ReactRouter.Route path="/:transactionType">
          <Transaction />
        </ReactRouter.Route>
      </ReactRouter.Switch>
    </Suspense.Boundary>
  )
}
