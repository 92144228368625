import * as Data from './data'
import * as R from 'ramda'

/**
 * Set up action exchange with host application.
 * Middleware subscribes to incoming post messages and dispatches ones with
 * matching types.
 * Also it intercepts specific actions to proxy them to host application.
 * @param {Object} store Redux store
 * @returns {func} Passes action forward
 */
export function postMessagingMiddleware(store) {
  if (!process.browser) {
    return (next) => (action) => next(action)
  }

  /**
   * Action types to be dispatched when received via `postMessage`
   * @constant {string[]}
   */
  const ALLOWED_INCOMING = [Data.UPDATE_INFORMATION]

  /**
   * Action types to be sent via `postMessage` when dispatched
   * @constant {string[]}
   */
  const ALLOWED_OUTGOING = [
    Data.INITIATED,
    Data.NEW_VERSION,
    Data.STEP_CHANGED,
    Data.PAYMENT_METHOD_SELECTED,
    Data.CRYPTO_CURRENCY_SELECTED,
    Data.TRANSACTION_CANCELED,
    Data.TRANSACTION_FAILED,
    Data.TRANSACTION_STARTED,
    Data.TRANSACTION_SUCCEEDED,
  ]

  window.addEventListener('message', (event) => {
    const action = R.pathOr({}, ['data'], event)
    if (R.contains(action.type, ALLOWED_INCOMING)) {
      switch (action.type) {
        case Data.UPDATE_INFORMATION: {
          store.dispatch(Data.updateInformation(action.payload))
          break
        }

        default: {
          store.dispatch(action)
          break
        }
      }
    }
  })

  return (next) => (action) => {
    if (R.contains(action.type, ALLOWED_OUTGOING)) {
      window.top.postMessage(action, '*')
    }

    // pass action to next middleware
    return next(action)
  }
}
