import * as R from 'ramda'

import {BackendType} from './constants'
import assert from 'assert'
import template from 'url-template'

/**
 * Action type for API actions.
 *
 * @constant
 * @type {string}
 */
export const PAYMENTS_API_REQUEST = 'payments/API_REQUEST'

/**
 * Resolves url template by given parameters.
 *
 * @param {string} urlTemplate URL template
 * @param {Object} parameters Parameters to resolve url template with
 * @returns {string} Request url
 */
export function renderUrlTemplate(urlTemplate, parameters) {
  const target = template.parse(urlTemplate)

  return target.expand(R.reject(R.isNil, parameters))
}

/**
 * Resolves body template by given parameters
 *
 * Body template is expected to be object with properties that must be included
 * into request body with values being another body template or a boolean
 * marking if value must be included even if null-like.
 *
 * ```js
 * {
 *   amount: true,
 *   extra_attributes: {
 *     affiliateId: false,
 *     language: true,
 *   },
 *   providerType: true,
 * }
 * ```
 *
 * @param {!Object} bodyTemplate Body template
 * @param {!Object} data Parameters to resolve body template with
 * @returns {Object} Request body
 */
export function renderBodyTemplate(bodyTemplate, data) {
  const body = {}
  for (const key in bodyTemplate) {
    const template = bodyTemplate[key]
    const value =
      typeof template === 'boolean'
        ? data[key]
        : renderBodyTemplate(template, data[key])
    if (value != null || template) {
      body[key] = value
    }
  }
  return body
}

/**
 * Create action to perform PaymenIQ API request.
 *
 * @param {Object} params
 * @param {BackendType} params.backendType Payment backend to use
 * @param {string} params.urlTemplate
 *   URL template for endpoint to make request to
 * @param {Object} [params.bodyTemplate] {@link renderBodyTemplate}
 * @param {Object} [params.requestData]
 *   Shallow object with data used to create request URL and body
 * @param {Object} [params.requestHeaders]
 *   Valid HTTP headers to make request with
 * @param {("GET"|"POST"|"DELETE")} params.requestMethod HTTP verb
 * @returns {ReduxAction}
 */
export function createRequest({
  backendType,
  bodyTemplate = {},
  requestData = {},
  requestHeaders,
  requestMethod,
  urlTemplate,
}) {
  if (process.env.NODE_ENV !== 'production') {
    assert(typeof backendType === 'string', '"backendType" must be a string')
    assert(
      typeof BackendType[backendType] === 'string',
      `"${backendType}" backend is not supported`
    )
    assert(typeof urlTemplate === 'string', '"urlTemplate" must be a string')
  }

  return {
    type: PAYMENTS_API_REQUEST,
    payload: {
      backendType,
      bodyTemplate,
      requestData,
      requestHeaders,
      requestMethod,
      urlTemplate,
    },
  }
}
