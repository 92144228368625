export function format(string = '') {
  const stringLowerCased = string.toLowerCase()

  if (stringLowerCased.includes('_')) {
    return stringLowerCased.split('_').map(format).join('')
  }

  return stringLowerCased.charAt(0).toUpperCase() + stringLowerCased.slice(1)
}

/**
 * Create unique payment method ID
 * @param {Object} params
 * @param {BackendType} params.backendType
 * @param {string} params.providerType
 * @param {string} [params.serviceType]
 * @param {string} params.transactionType
 * @returns {PaymentMethodId}
 */

export function createPaymentMethodId({
  backendType,
  providerType,
  serviceType = '',
  transactionType,
}) {
  return [backendType, providerType, serviceType, transactionType]
    .map(format)
    .join('')
}
