import * as Actions from '../actions'
import * as Constants from '../constants'
import * as Processes from '@rushplay/processes'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'

function createKycPaymentMethod(transactionType, providerType) {
  switch (transactionType) {
    case Constants.TransactionType.AUTH: {
      return {
        accounts: [],
        backendType: Constants.BackendType.PIQKYC,
        canAddAccount: true,
        id: createPaymentMethodId({
          backendType: Constants.BackendType.PIQKYC,
          providerType,
          transactionType: Constants.TransactionType.AUTH,
        }),
        limit: {
          max: null,
          min: null,
        },
        providerType,
        transactionType: Constants.TransactionType.AUTH,
      }
    }

    case Constants.TransactionType.DEPOSIT: {
      return {
        accounts: [],
        backendType: Constants.BackendType.PIQKYC,
        canAddAccount: true,
        id: createPaymentMethodId({
          backendType: Constants.BackendType.PIQKYC,
          providerType,
          transactionType: Constants.TransactionType.DEPOSIT,
        }),
        limit: {
          // These limits are not possible to fetch from PIQ and must be
          // hardcoded for approriate values in the host application using this provider.
          max: Number.MAX_SAFE_INTEGER,
          min: 0,
        },
        providerType,
        transactionType: Constants.TransactionType.DEPOSIT,
      }
    }

    default: {
      return {}
    }
  }
}

/**
 * Fetches user’s payment methods.
 *
 * @param {Object} params
 * @param {ProviderType} params.providerType
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethods(params) {
  const processId = 'piqkyc/fetch-payments-methods-by-user'

  const paymentMethod = createKycPaymentMethod(
    params.transactionType,
    params.providerType
  )

  return [
    // This little hack introducec psuedo-async to get a process to track
    // syncronious data. We're doing this to prevent faulty-renders based on states that happend before
    // there's been a chance to fetch anything.
    Processes.start(processId),
    bind({type: 'NOOP'}, () => [
      Actions.updatePaymentMethods([paymentMethod]),
      Processes.stop(processId),
    ]),
  ]
}

/**
 * Start payment transaction
 *
 * @returns Redux Effects action
 */
export function performTransaction(providerType) {
  return Actions.startTransaction({
    redirectOutput: {container: providerType},
    transactionState: Constants.TransactionState.WAITING_INPUT,
  })
}
