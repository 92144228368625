import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Extracted logic for basic SVG-component
 * @component SvgIcon
 * @param {Object} props Components props
 * @param {ReactNode} props.children Svg paths
 * @returns {ReactNode} SVG icon
 */
function SvgIcon(props) {
  return (
    <ThemeUi.Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      sx={{
        mt: '-0.105em',
        mb: '-0.145em',
        height: '1.25em',
        width: '1.25em',
        verticalAlign: '-0.185em',
      }}
    >
      {props.children}
    </ThemeUi.Box>
  )
}

SvgIcon.propTypes = {
  children: PropTypes.node,
}

/**
 * Cancel icon
 * @see {@link https://material.io/resources/icons/?icon=cancel&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Cancel() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
      />
    </SvgIcon>
  )
}

/**
 * Close icon
 * @see {@link https://material.io/resources/icons/?icon=close&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Close() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </SvgIcon>
  )
}

/**
 * Error icon
 * @see {@link https://material.io/resources/icons/?icon=error&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Error() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
      />
    </SvgIcon>
  )
}

/**
 * Info icon
 * @see {@link https://material.io/resources/icons/?icon=info&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Info() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      />
    </SvgIcon>
  )
}

/**
 * Navigate Back icon
 * @see {@link https://material.io/resources/icons/?icon=navigate_before&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function NavigateBack() {
  return (
    <SvgIcon>
      <path
        fill="currentColor"
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </SvgIcon>
  )
}

/**
 * Success icon
 * @see {@link https://material.io/resources/icons/?icon=check_circle&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Success() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      />
    </SvgIcon>
  )
}

/**
 * Warning icon
 * @see {@link https://material.io/resources/icons/?icon=warning&style=baseline}
 * @returns {ReactNode} svg icon
 */
export function Warning() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      />
    </SvgIcon>
  )
}

/**
 * Settings icon
 * @see {@link https://fonts.google.com/icons?selected=Material%20Icons%3Asettings%3A}
 * @returns {ReactNode} svg icon
 */
export function Settings() {
  return (
    <SvgIcon>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M19.5,12c0-0.23-0.01-0.45-0.03-0.68l1.86-1.41c0.4-0.3,0.51-0.86,0.26-1.3l-1.87-3.23c-0.25-0.44-0.79-0.62-1.25-0.42 l-2.15,0.91c-0.37-0.26-0.76-0.49-1.17-0.68l-0.29-2.31C14.8,2.38,14.37,2,13.87,2h-3.73C9.63,2,9.2,2.38,9.14,2.88L8.85,5.19 c-0.41,0.19-0.8,0.42-1.17,0.68L5.53,4.96c-0.46-0.2-1-0.02-1.25,0.42L2.41,8.62c-0.25,0.44-0.14,0.99,0.26,1.3l1.86,1.41 C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-1.86,1.41c-0.4,0.3-0.51,0.86-0.26,1.3l1.87,3.23c0.25,0.44,0.79,0.62,1.25,0.42 l2.15-0.91c0.37,0.26,0.76,0.49,1.17,0.68l0.29,2.31C9.2,21.62,9.63,22,10.13,22h3.73c0.5,0,0.93-0.38,0.99-0.88l0.29-2.31 c0.41-0.19,0.8-0.42,1.17-0.68l2.15,0.91c0.46,0.2,1,0.02,1.25-0.42l1.87-3.23c0.25-0.44,0.14-0.99-0.26-1.3l-1.86-1.41 C19.49,12.45,19.5,12.23,19.5,12z M12.04,15.5c-1.93,0-3.5-1.57-3.5-3.5s1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5S13.97,15.5,12.04,15.5z"
      />
    </SvgIcon>
  )
}
