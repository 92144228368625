import PropTypes from 'prop-types'
import {useAccounts} from './use-accounts'

/**
 * @typedef {Object} AccountsRenderProps
 * @property {Array.<Object>} accounts List of accounts IDs
 */

export function Accounts(props) {
  if (process.env.NODE_ENV) {
    // eslint-disable-next-line no-console
    console.warn(
      'DEPRECATED',
      '<Accounts> component is deprecated in favor of `useAccounts` hook'
    )
  }

  const accounts = useAccounts({
    paymentMethodId: props.paymentMethodId,
  })
  return props.children(accounts)
}

Accounts.propTypes = {
  children: PropTypes.func,
  paymentMethodId: PropTypes.string,
}
