import * as Actions from '../actions'
import * as Constants from '../constants'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as Request from '../request'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'

function transformTransactionType(txType) {
  switch (txType) {
    case 'deposit': {
      return Constants.TransactionType.DEPOSIT
    }

    default: {
      throw new Error('Unknown transaction type')
    }
  }
}

function transformPaymentMethods(paymentMethods, txType) {
  return R.reduce(
    (methods, method) => {
      const transactionType = transformTransactionType(method.transaction_type)

      if (transactionType === txType) {
        const backendType = Constants.BackendType.PWCLICK

        return R.append(
          {
            accounts: [],
            backendType,
            canAddAccount: true,
            id: createPaymentMethodId({
              backendType,
              providerType: method.provider,
              transactionType,
            }),
            limit: {
              max: `${method.limits.max_cents / 100}`,
              min: `${method.limits.min_cents / 100}`,
            },
            providerType: method.provider,
            transactionType,
          },
          methods
        )
      }

      return methods
    },
    [],
    paymentMethods
  )
}

/**
 * Fetches user’s payment methods.
 *
 * @param {Object} params
 * @param {string} params.countryCode Country code used for currency conversion
 * @param {SessionId} params.sessionId
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethods(params) {
  const processId = `pwclick/fetch-payments-methods-by-user`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.PWCLICK,
        requestData: {
          countryCode: params.countryCode,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        requestMethod: 'GET',
        urlTemplate: '/pw_click/payment_methods{?countryCode}',
      }),
      (res) => [
        Actions.updatePaymentMethods(
          transformPaymentMethods(res.value, params.transactionType)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePaymentMethods(
            new Error('errors.pw_click.fetch-payment-methods-by-user-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Start payment transaction
 *
 * @returns Redux Effects action
 */
export function performTransaction() {
  return Actions.startTransaction({
    redirectOutput: {container: 'pwclick'},
    transactionState: Constants.TransactionState.WAITING_INPUT,
  })
}
