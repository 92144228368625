import * as R from 'ramda'

import PropTypes from 'prop-types'

/**
 * @typedef {Object} PendingTransactionRenderProps
 * @property {Object} pending transaction
 */

export function PendingTransaction(props) {
  return props.children(
    R.merge(props.transaction, {
      onCancel: props.onCancel,
      cancelling: props.cancelling,
    })
  )
}

PendingTransaction.propTypes = {
  children: PropTypes.func,
  onCancel: PropTypes.func,
  cancelling: PropTypes.bool,
  transaction: PropTypes.object,
}
