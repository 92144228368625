import * as R from 'ramda'
import * as date from 'date-fns'

import {BackendType} from '../constants'
import {createPaymentMethodId} from '../create-provider-id'
import {getService} from '../getService'

/**
 * @typedef {Object} PiqAccount
 * @prop {string} accountId
 * @prop {string} [description]
 * @prop {string} [lastSuccess]
 * @prop {string} [lastUsed]
 * @prop {string} maskedAccount
 * @prop {string} type
 * @prop {boolean} visible
 * @prop {boolean} expired
 * @prop {("ACTIVE")} status
 * @prop {string} [cardType]
 * @prop {string} [cardHolder]
 * @prop {string} [expiryDate]
 * @prop {string} service
 */

/**
 * @typedef {Object} PiqPaymentMethod
 * @prop {number} toUserCurrencyBuyRate
 * @prop {string} txType
 * @prop {*} accountSettings
 * @prop {Array<PiqAccount>} accounts
 * @prop {Object} limit
 * @prop {string} limit.max
 * @prop {string} limit.min
 * @prop {Array<string>} limit.fixed
 * @prop {Array<Object>} fees
 * @prop {number} pspToUserCurrencyBuyRate
 * @prop {string} providerType
 * @prop {*} service
 * @prop {string} pspCurrency
 * @prop {boolean} canAddAccount
 * @prop {Object} maintenanceInfo
 * @prop {boolean} maintenanceInfo.maintenance
 * @prop {string} [maintenanceInfo.from]
 * @prop {string} [maintenanceInfo.to]
 * @prop {string} [maintenanceInfo.message]
 */

/**
 * @typedef {Object} PiqPaymentMethodsResponse
 * @prop {number} merchantId
 * @prop {string} userId
 * @prop {boolean} success
 * @prop {Array<PiqPaymentMethod>} methods
 * @prop {Object} feeInfo
 */

function parseTransactionType(txType) {
  if (txType.indexOf('Deposit') > -1) {
    return 'deposit'
  }

  if (txType.indexOf('Withdrawal') > -1) {
    return 'withdrawal'
  }
}

/**
 * Returns boolean
 *
 * @param {PiqPaymentMethod} method method
 * @returns {boolean} boolean
 */

export function shouldIgnoreAccounts(method) {
  if (method.service === 'FLYKK') {
    // ISX has advised that for Flykk service the customer is expected to enter
    // the card number for each deposit and withrawal
    return true
  }

  if (method.providerType === 'CRYPTOCURRENCY') {
    // ISX has advised that for all cryptocurrency providers the customer is
    // expected to enter the wallet address for each deposit and withrawal
    return true
  }

  if (method.txType === 'WebRedirectDeposit') {
    // ISX has advised that for WebRedirectDeposit txType transactions the customer is
    // expected to enter the wallet address for each deposit.
    // Reason: if no account was saved in this type, provider suggests accounts saved for other services
    return true
  }

  return false
}
/**
 * Returns normalized payment methods.
 *
 * @param {PiqPaymentMethodsResponse} response Response
 * @returns {Array.<PaymentMethod>} PaymentMethods
 */
export function getPaymentMethods(response) {
  return R.map((method) => {
    const backendType = BackendType.PIQ
    const serviceType = getService(method.service)
    const transactionType = parseTransactionType(method.txType)
    return {
      accounts: shouldIgnoreAccounts(method)
        ? []
        : R.pluck('accountId', R.pathOr([], ['accounts'], method)),
      backendType,
      canAddAccount: method.canAddAccount,
      fees: method.fees,
      id: createPaymentMethodId({
        backendType,
        providerType: method.providerType,
        serviceType,
        transactionType,
      }),
      limit: method.limit,
      providerType: method.providerType,
      // TODO: remove serviceType when payer is integrated
      serviceType,
      transactionType,
    }
  }, R.pathOr([], ['methods'], response))
}

/**
 * Returns accounts.
 *
 * @param {Object} response Response
 * @returns {Array<Object>} Accounts
 */
export function getAccountsFromPaymentMethods(response) {
  return R.reduce(
    (accounts, method) => {
      if (shouldIgnoreAccounts(method)) {
        return accounts
      }

      if (R.isEmpty(R.prop(['accounts'], method))) {
        return accounts
      }

      return R.concat(accounts, method.accounts)
    },
    [],
    R.pathOr([], ['methods'], response)
  )
}

/**
 * Returns pending transactions.
 *
 * @param {Object} response Response
 * @returns {Array<Object>} Pending transactions
 */
export function getPendingTransactions(response) {
  return R.sort(
    (a, b) =>
      date.getTime(new Date(b.created)) - date.getTime(new Date(a.created)),
    R.pathOr([], ['transactions'], response)
  )
}

/**
 * Returns normalized locale.
 *
 * @param {string} paymentMethodId Payment Method Id
 * @param {string} locale Locale fallback
 * @param {string} countryCode user country code
 * @param {string} language user language
 * @returns {string} Locale
 */
export function toNormalizedLocale(
  paymentMethodId,
  locale,
  language,
  countryCode
) {
  switch (paymentMethodId) {
    case 'PiqCommunitybankDeposit':
    case 'PiqBankRedeemVoucherDeposit':
    case 'PiqBankBtvoucherDeposit':
      return `${language}_${countryCode.toUpperCase()}`
    default:
      return locale
  }
}
