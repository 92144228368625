import * as R from 'ramda'

import {createSelector} from 'reselect'

function getProp(prop) {
  return (state, props) => R.path([prop], props)
}

function getAllPaymentMethods(state) {
  return state.paymentMethods || {}
}

function __createPaymentMethodsSelector() {
  return createSelector(
    [
      getAllPaymentMethods,
      getProp('paymentMethodId'),
      getProp('transactionType'),
    ],
    (paymentMethods, paymentMethodId, transactionType) => {
      return R.reduce(
        (result, paymentMethod) => {
          if (paymentMethodId != null) {
            if (paymentMethod.id === paymentMethodId) {
              return R.reduced(R.append(paymentMethod, result))
            }

            return result
          }

          if (transactionType != null) {
            if (paymentMethod.transactionType === transactionType) {
              return R.append(paymentMethod, result)
            }

            return result
          }

          return R.append(paymentMethod, result)
        },
        [],
        R.values(paymentMethods)
      )
    }
  )
}

export function getAllAccounts(state) {
  return R.propOr([], ['accounts'], state)
}

/**
 * Get list of payment methods IDs.
 *
 * @type {function(Object, Object): Array.<PaymentMethodId>}
 * @param {Object} state
 * @param {Object} props
 * @param {TransactionType} [props.transactionType]
 * @returns {Array.<PaymentMethodId>} payment method ids matching the provided
 * transaction type, if any, or all payment method ids if type is omitted.
 */
export const getPaymentMethods = createSelector(
  [getAllPaymentMethods, getProp('transactionType')],
  (paymentMethods, transactionType) =>
    R.keys(
      transactionType
        ? R.pickBy(
            (value) => value.transactionType === transactionType,
            paymentMethods
          )
        : paymentMethods
    )
)

/**
 * Sorts accounts by last successful usage
 * @param {Object[]} accounts paymentMethod accounts from payments
 * @returns {Object[]}
 */
function sortByLastSuccess(accounts) {
  return R.sort((a, b) => {
    const aDate = a.lastSuccess ? new Date(a.lastSuccess).getTime() : 0
    const bDate = b.lastSuccess ? new Date(b.lastSuccess).getTime() : 0
    return bDate - aDate
  }, accounts)
}

/**
 * Gives usable accounts and paymentmethods sorted with data to show for the user
 * @param {Object} state Redux state
 * @param {('deposit' | 'withdraw' | 'auth')} transactionType
 * @returns {Object}
 */

export const getSortedPaymentMethods = createSelector(
  [
    (state, transactionType) =>
      getPaymentMethods(state.payments, transactionType),
    (state) => state.payments,
    (state) => state.configuration.currency,
  ],
  (paymentMethods, payments, currency) => {
    const converterValue = currency === 'UBT' ? 100000000 : 100
    const methods = paymentMethods.map((id) => {
      const paymentMethod = getPaymentMethod(payments, {id})

      return {
        accounts: paymentMethod.accounts,
        canAddAccount: paymentMethod.canAddAccount,
        // TODO: use payments.getFee selector once it has been created (https://app.clickup.com/t/7wmmvv)
        feePercent:
          R.pathOr(0, ['fees', 0, 'percentageFee'], paymentMethod) *
          converterValue,
        id,
        maxLimitCents:
          parseFloat(
            R.pathOr(Number.MAX_SAFE_INTEGER, ['limit', 'max'], paymentMethod)
          ) * converterValue,
        minLimitCents:
          parseFloat(R.pathOr(0, ['limit', 'min'], paymentMethod)) *
          converterValue,
        type: paymentMethod.providerType,
      }
    })

    // Sorts out payment methods where you can create a new transaction
    const validPaymentMethods = R.reject(
      (method) => R.propEq('canAddAccount', false, method.canAddAccount),
      methods
    )

    // Creates a new entity with mixed data from accounts and paymentMethod
    const accounts = methods.map((paymentMethod) =>
      paymentMethod.accounts.map((id) => {
        const account = getAccount(payments, {id})

        return {
          feePercent: paymentMethod.feePercent,
          id,
          lastSuccess: account.lastSuccess,
          maskedAccount: account.maskedAccount,
          maxLimitCents: paymentMethod.maxLimitCents,
          minLimitCents: paymentMethod.minLimitCents,
          paymentMethodId: paymentMethod.id,
          type: paymentMethod.type,
        }
      })
    )

    // Sorts out accounts that were never successful
    const validAccounts = R.reject(
      (account) => R.isNil(account.lastSuccess),
      R.flatten(accounts)
    )

    return {
      accounts: sortByLastSuccess(validAccounts),
      paymentMethods: validPaymentMethods,
    }
  }
)

/**
 * Get provider by ID or accountId.
 *
 * @type {function(Object, Object): PaymentMethod}
 * @param {Object} state
 * @param {Object} props
 * @param {PaymentMethodId} props.id
 * @param {string} props.accountId
 * @returns {PaymentMethod}
 */
export const getPaymentMethod = createSelector(
  [getAllPaymentMethods, getProp('id'), getProp('accountId')],
  (paymentMethods, id, accountId) => {
    if (accountId) {
      return R.path(
        ['id'],
        R.find(
          (paymentMethod) => R.contains(accountId, paymentMethod.accounts),
          R.values(paymentMethods)
        )
      )
    }

    return R.prop(id, paymentMethods)
  }
)
/**
 * Get list of account IDs.
 *
 * @type {function(Object, Object): Array.<string>}
 * @param {Object} state
 * @param {Object} [props]
 * @param {PaymentMethodId} [props.paymentMethodId] Payment method ID to filter accounts by
 * @param {TransactionType} [props.transactionType] Transaction type to filter accounts by
 * @returns {Array.<string>}
 */
export const getAccounts = createSelector(
  [
    (state) => R.keys(getAllAccounts(state)),
    (state, props) =>
      R.path(['paymentMethodId'], props)
        ? R.propOr(
            [],
            'accounts',
            getPaymentMethod(state, {
              id: R.prop('paymentMethodId', props),
            })
          )
        : undefined,
    (state, props) =>
      R.uniq(
        R.flatten(
          R.reduce(
            (acc, method) =>
              R.append(
                R.propOr([], 'accounts', getPaymentMethod(state, {id: method})),
                acc
              ),
            [],
            getPaymentMethods(state, props)
          )
        )
      ),
  ],
  (accountIds, paymentMethodAccounts, transactionTypeAccounts) => {
    if (paymentMethodAccounts) {
      return R.intersection(accountIds, paymentMethodAccounts)
    } else if (R.not(R.isEmpty(transactionTypeAccounts))) {
      return R.intersection(accountIds, transactionTypeAccounts)
    } else {
      return accountIds
    }
  }
)

/**
 * Get account by ID.
 *
 * @type {function(Object, Object): Object}
 * @param {Object} state
 * @param {Object} props
 * @param {string} [props.id]
 * @returns {Object}
 */
export const getAccount = createSelector(
  getAllAccounts,
  getProp('id'),
  (accounts, accountId) => R.propOr(null, accountId, accounts)
)

/**
 * Get list of pending transaction IDs.
 *
 * @param {Object} state
 * @param {Object} props
 * @param {TransactionType} [props.transactionType] Transaction type to filter pending transaction by
 * @param {Array.<TransactionState>} [props.onlyStates] Transaction states to filter pending transactions by
 * @returns {Array.<string>}
 */
export function getPendingTransactions(state, props) {
  const transactionType = R.path(['transactionType'], props)
  const onlyStates = R.path(['onlyStates'], props)

  if (onlyStates) {
    return R.map(
      R.path(['transactionId']),
      R.or(
        R.filter(
          (tx) => R.contains(tx.state, onlyStates),
          state.pendingTransactions
        ),
        []
      )
    )
  }

  return R.map(
    R.path(['transactionId']),
    transactionType
      ? R.filter(
          (tx) => R.toLower(tx.txType).includes(transactionType),
          state.pendingTransactions
        )
      : state.pendingTransactions
  )
}

/**
 * Get pending transaction by ID.
 *
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.transactionId
 * @returns {object} PendingTransaction model
 */
export function getPendingTransaction(state, props) {
  return R.find(
    R.propEq('transactionId', props.transactionId),
    state.pendingTransactions
  )
}

/**
 * Get redirect parameters.
 *
 * @param {Object} state
 * @returns {Object} redirectOutput from Payment IQ
 */
export function getRedirectParams(state) {
  const redirectOutput = R.path(['redirectOutput'], state.activeTransaction)

  if (redirectOutput == null) {
    return null
  }

  return {
    container: redirectOutput.container,
    height: redirectOutput.height,
    html: redirectOutput.html,
    method: redirectOutput.method,
    parameters: redirectOutput.parameters,
    url: redirectOutput.url,
    width: redirectOutput.width,
  }
}

/**
 * Get redirect parameters.
 *
 * @param {Object} state
 * @returns {Object} redirectOutput from Payment IQ
 */
export function hasRedirectParams(state) {
  return R.not(R.isEmpty(getRedirectParams(state)))
}

/**
 * Get active transaction ID
 *
 * @param {Object} state
 * @returns {TransactionState} Transaction state
 */
export function getTransactionId(state) {
  return R.pathOr(null, ['transactionId'], state.activeTransaction)
}

/**
 * Get active transaction state
 *
 * @param {Object} state
 * @returns {TransactionState} Transaction state
 */
export function getTransactionState(state) {
  return R.pathOr(null, ['transactionState'], state.activeTransaction)
}

/**
 * Get active payment ID
 *
 * @param {Object} state
 * @returns {PaymentMethod}
 */
export function getActiveTransactionPaymentId(state) {
  return R.pathOr(null, ['paymentMethodId'], state.activeTransaction)
}

/**
 * Get active transaction url.
 *
 * @param {Object} state
 * @returns {Object} redirect url from Payment IQ
 */
export function getActiveTransactionUrl(state) {
  return R.propOr('', 'popupRedirectUrl', getRedirectParams(state))
}

/**
 * Get current page.
 *
 * @param {Object} state
 * @returns {number} currentPage Current page
 */
export function getCurrentPage(state) {
  return R.prop('currentPage', state.receipts)
}

/**
 * Get users receipts.
 *
 * @param {Object} state
 * @returns {Receipts}
 */
export function getReceipts(state) {
  return R.propOr([], 'transactions', state.receipts)
}

/**
 * Get receipt by transaction id.
 *
 * @param {Object} state
 * @param {Object} props
 * @param {Receipt["transactionId"]} props.transactionId
 * @returns {Receipt}
 */
export function getReceipt(state, props) {
  return R.find(
    R.propEq('transactionId', props.transactionId),
    R.prop('transactions', state.receipts)
  )
}

/**
 * Get total pages.
 *
 * @param {Object} state
 * @returns {ReceiptMetaData["total_pages"]}
 */
export function getTotalPages(state) {
  return R.prop('totalPages', state.receipts)
}

/**
 * Get total deposits crossbrand.
 *
 * @param {Object} state
 * @returns {Object}
 */
export function getTotalDepositsCents(state) {
  return R.path(['totalDepositCents'], state)
}

function coerceLimits(limits, currency) {
  // Value of 100000000 converts BTC to mBTC and compensate for
  // how we display UBT currency (1000 * 100000)
  // https://github.com/RushPlay/common/blob/master/src/currencies.js#L137
  const converterValue = currency === 'UBT' ? 100000000 : 100
  const max = parseFloat(R.path(['max'], limits)) * converterValue
  const min = parseFloat(R.path(['min'], limits)) * converterValue
  return {
    max: R.defaultTo(null, max),
    min: R.defaultTo(null, min),
  }
}

/**
 * Create memoized `getLimits` selector instance
 */
export function createLimitsSelector() {
  const __getPaymentMethods = __createPaymentMethodsSelector()
  /**
   * Get limits in cents
   * @name getLimits
   * @param {Object} state
   * @param {Object} props
   * @param {PaymentMethodId} props.paymentMethodId
   * @param {TransactionType} props.transactionType
   */
  return createSelector(
    [__getPaymentMethods, getProp('currency')],
    (paymentMethods, currency) => {
      const limits = R.reduce(
        (finalLimits, paymentMethod) => {
          const currentLimits = R.pathOr({}, ['limit'], paymentMethod)
          return {
            max:
              finalLimits.max != null && currentLimits.max != null
                ? Math.max(finalLimits.max, currentLimits.max)
                : finalLimits.max || currentLimits.max,
            min:
              finalLimits.min != null && currentLimits.min != null
                ? Math.min(finalLimits.min, currentLimits.min)
                : finalLimits.min || currentLimits.min,
          }
        },
        {},
        paymentMethods
      )
      return coerceLimits(limits, currency)
    }
  )
}

export function createSchemaSelector(baseSchema) {
  const __getPaymentMethods = __createPaymentMethodsSelector()

  const getMaxAmountCents = createSelector(
    [getProp('maxAmountCents')],
    (maxAmountCents) => R.defaultTo(Infinity, maxAmountCents)
  )

  const getLimits = createLimitsSelector()

  /**
   * Gets data schema by paymentMethodId or transactionType.
   *
   * @private
   *
   * @param {Object} state
   * @param {Object} props
   * @param {TransactionType} [props.maxAmountCents]
   *  Additional upper limit to take into account, e.g. player’s balance. Will
   *  take precedence over payment method limits if lower.
   * @param {TransactionType} props.transactionType
   * @returns {Object} payment method schema
   */
  return createSelector(
    [__getPaymentMethods, getLimits, getMaxAmountCents, getProp('currency')],
    (paymentMethods, limits, maxAmountCents, currency) =>
      R.pipe(
        R.assocPath(['properties', 'amount', 'minimum'], limits.min),
        R.assocPath(
          ['properties', 'amount', 'maximum'],
          Math.min(maxAmountCents, limits.max)
        ),
        (schema) =>
          R.reduce(
            (finalSchema, paymentMethod) => {
              if (!R.path(['definitions', paymentMethod.id], finalSchema)) {
                return finalSchema
              }

              const paymentMethodLimits = coerceLimits(
                paymentMethod.limit,
                currency
              )

              const amountSchemaSlice = R.reject(R.isNil, {
                maximum: paymentMethodLimits.max,
                minimum: paymentMethodLimits.min,
              })

              if (R.isEmpty(amountSchemaSlice)) {
                return finalSchema
              }

              return R.over(
                R.lensPath([
                  'definitions',
                  paymentMethod.id,
                  'properties',
                  'amount',
                ]),
                R.mergeRight(amountSchemaSlice)
              )(finalSchema)
            },
            schema,
            R.values(paymentMethods)
          )
      )(baseSchema)
  )
}

export function createSubstateSelector() {
  return createSelector([(state, props) => state[props.mountPoint]], R.identity)
}

/**
 * Get transaction error
 *
 * @param {Object} state
 * @returns {string} Transaction error
 */
export function getTransactionError(state) {
  return R.pathOr(null, ['error'], state.activeTransaction)
}
