/**
 * Theme data for `theme-ui`
 * @constant {Object}
 * @see {@link https://theme-ui.com/theming#example-theme}
 */
export const defaultTheme = {
  breakpoints: ['350px', '800px'],
  space: [8, 16, 32],
  shadows: ['0 3px 14px 0 rgba(0, 0, 0, 0.1)'],
  fonts: {
    body: 'Verdana, sans-serif',
    heading: 'Verdana, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [10, 12, 16, 20, 24, 32],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: '#343a40',
    link: '#343a40',
    background: '#f8f9fa',
    navBackground: '#f8f9fa',
    primary: '#007bff',
    secondary: '#6c757d',
    muted: '#cbcbcb',
    formLabel: '#70787f',
    disabled: '#6c757d',
    paymentMethodBackground: '#ffffff',
    paymentMethodText: '#343a40',
    warning: '#ffc107',
    danger: '#dc3545',
    dangerMuted: '#ffd5d9',
    success: '#28a745',
    info: '#007bff',
    infoMuted: '#b5d5fe',
  },
  radii: [4, 8, 16],
  text: {
    color: 'text',
    title: {
      fontSize: 2,
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  styles: {
    a: {
      color: 'link',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    hr: {
      my: 0,
      mx: '0px',
      color: 'muted',
    },
    root: {
      'minHeight': '100vh',
      'display': 'flex',
      'm': '0px',
      'fontFamily': 'body',
      'code': {
        bg: '#e8e8e8',
        color: 'danger',
        px: '4px',
        my: '4px',
        fontFamily: 'monospace',
        fontWeight: 'body',
      },
      'WebkitOverflowScrolling': 'touch',
      '@media (hover: hover)': {
        '::-webkit-scrollbar': {
          width: '3px',
          bg: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          bg: 'transparent',
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-track': {
          bg: 'background',
        },
        '&:hover': {
          '::-webkit-scrollbar-thumb': {
            bg: 'muted',
          },
        },
      },
    },
  },
  forms: {
    input: {
      'py': 0,
      'px': 1,
      'fontSize': 2,
      'fontWeight': 'bold',
      'borderRadius': 0,
      'backgroundColor': 'white',
      'borderColor': 'muted',
      'transition': 'border-color 150ms ease-in-out',
      'lineHeight': 'body',
      '&:focus': {
        borderColor: 'primary',
      },
      '&:disabled, &:read-only': {
        'cursor': 'not-allowed',
        'borderColor': 'disabled',
        '&:hover': {
          borderColor: 'disabled',
        },
      },
      '&::placeholder': {
        fontWeight: 'body',
      },
    },
    select: {
      'py': 0,
      'px': 1,
      'fontSize': 2,
      'fontWeight': 'bold',
      'backgroundColor': 'white',
      'borderRadius': 0,
      'transition': 'border-color 150ms ease-in-out',
      'lineHeight': 'body',
      '&:focus': {
        borderColor: 'primary',
      },
      '&:disabled, &:read-only': {
        'cursor': 'not-allowed',
        'borderColor': 'disabled',
        '&:hover': {
          borderColor: 'disabled',
        },
      },
    },
    checkbox: {
      mr: 0,
      cursor: 'pointer',
      borderRadius: 0,
    },
    checkboxLabel: {
      fontSize: 1,
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'body',
      cursor: 'pointer',
    },
    label: {
      fontSize: 1,
      fontWeight: 'bold',
      color: 'formLabel',
      mb: '4px',
    },
  },
  buttons: {
    primary: {
      'py': 0,
      'px': 1,
      'fontWeight': 'bold',
      'textTransform': 'capitalize',
      'lineHeight': 'body',
      'transition': 'transform 150ms ease-in-out',
      'cursor': 'pointer',
      '&:hover': {
        opacity: 0.9,
      },
      '&:active': {
        transform: 'scale(0.975)',
      },
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  links: {
    nav: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    text: {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  messages: {
    info: {
      bg: 'infoMuted',
      borderLeftWidth: '3px',
      borderLeftColor: 'info',
      p: 1,
      width: '100%',
    },
    fieldError: {
      border: 'none',
      bg: 'dangerMuted',
      color: 'danger',
      p: '4px',
      width: '100%',
      wordBreak: 'break-word',
      fontSize: 0,
    },
  },
}
