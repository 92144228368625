import * as Configuration from './configuration'
import * as Herz from '@rushplay/herz'

import {App} from './app'
import {GlobalProvider} from './global-provider'
import React from 'react'
import {createStore} from './create-store'
import {hydrate} from 'react-dom'
import {loadableReady} from '@loadable/component'

// Picks up redux-store from server handover
const store = createStore(window.__APP_DATA__)
// Removes the object from window
delete window.__APP_DATA__

const state = store.getState()

// the client-side of the application
async function main() {
  const loader = new Herz.I18n.Loader({
    provider: Herz.I18n.SourceType.BABELFISK,
    url: Configuration.getI18nProviderUrl(state),
  })

  await loader.restoreCache(window.__I18N_DATA__)

  loadableReady(() => {
    hydrate(
      <GlobalProvider translationLoader={loader} store={store}>
        <App />
      </GlobalProvider>,
      document.getElementById('root')
    )
  })
}

main()

if (module.hot) {
  module.hot.accept()
}
