import * as Constants from './constants'
import * as R from 'ramda'

/**
 *
 * @param {string} serviceType
 * @returns {Service}
 */

export function getService(serviceType) {
  return serviceType
    ? R.pathOr('', [serviceType.toUpperCase()], Constants.ServiceType)
    : ''
}
