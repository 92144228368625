import PropTypes from 'prop-types'

/**
 * @typedef {Object} AccountRenderProps
 * @property {Object} account data
 */

export function Account(props) {
  return props.children(props.account)
}

Account.propTypes = {
  account: PropTypes.object,
  id: PropTypes.string,
  children: PropTypes.func,
}
