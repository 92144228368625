import * as R from 'ramda'
import * as selectors from '../../selectors'

import {Account as Component} from './account'
import {connect} from 'react-redux'

function mapStateToProps(state, props) {
  const paymentsState = R.prop(props.mountPoint, state)

  return {
    account: selectors.getAccount(paymentsState, props),
  }
}

export const Account = connect(mapStateToProps)(Component)
