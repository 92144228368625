import PropTypes from 'prop-types'
import {usePaymentMethod} from './use-payment-method'

/**
 * @typedef {Object} PaymentMethodRenderProps
 * @property {Object} payment method data
 */

export function PaymentMethod(props) {
  const paymentMethod = usePaymentMethod(props.id)
  return props.children(paymentMethod)
}

PaymentMethod.propTypes = {
  children: PropTypes.func,
  id: PropTypes.string.isRequired,
}
