import * as Actions from '../actions'
import * as Constants from '../constants'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as Request from '../request'
import * as date from 'date-fns'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'

function transformTransactionType(txType) {
  switch (txType) {
    case 'auth': {
      return Constants.TransactionType.AUTH
    }

    case 'deposit': {
      return Constants.TransactionType.DEPOSIT
    }

    case 'withdraw': {
      return Constants.TransactionType.WITHDRAWAL
    }

    default: {
      throw new Error('Unknown transaction type')
    }
  }
}

function transformPaymentMethods(paymentMethods, txType) {
  // TODO: Remove mock
  if (txType === Constants.TransactionType.AUTH) {
    return [
      {
        accounts: [],
        backendType: Constants.BackendType.ZIMPLER,
        canAddAccount: true,
        id: createPaymentMethodId({
          backendType: Constants.BackendType.ZIMPLER,
          providerType: 'zimpler',
          transactionType: Constants.TransactionType.AUTH,
        }),
        limit: {
          max: null,
          min: null,
        },
        providerType: 'zimpler',
        transactionType: Constants.TransactionType.AUTH,
      },
    ]
  }

  return R.reduce(
    (methods, method) => {
      const transactionType = transformTransactionType(method.transaction_type)

      if (transactionType === txType) {
        const backendType = Constants.BackendType.ZIMPLER

        return R.append(
          {
            accounts: [],
            backendType,
            canAddAccount: true,
            id: createPaymentMethodId({
              backendType,
              providerType: method.provider,
              transactionType,
            }),
            limit: {
              max:
                txType === Constants.TransactionType.AUTH
                  ? null
                  : `${method.limits.max_cents / 100}`,
              min:
                txType === Constants.TransactionType.AUTH
                  ? null
                  : `${method.limits.min_cents / 100}`,
            },
            providerType: method.provider,
            transactionType,
          },
          methods
        )
      }

      return methods
    },
    [],
    paymentMethods
  )
}

/**
 * Fetches user’s payment methods.
 *
 * @param {Object} params
 * @param {string} params.countryCode Country code used for currency conversion
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethods(params) {
  const processId = `zimpler/fetch-payments-methods-by-user`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.ZIMPLER,
        requestData: {
          countryCode: params.countryCode,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
        },
        requestMethod: 'GET',
        urlTemplate: '/zimpler/payment_methods{?countryCode}',
      }),
      (res) => [
        Actions.updatePaymentMethods(
          transformPaymentMethods(res.value, params.transactionType)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePaymentMethods(
            new Error('errors.zimpler.fetch-payment-methods-by-user-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

function transformPendingTransactions(transactions) {
  return R.sort(
    (a, b) =>
      date.getTime(new Date(b.created)) - date.getTime(new Date(a.created)),
    R.map(
      (transaction) =>
        R.mergeDeepRight(transaction, {
          amount: `${transaction.amount / 100} ${transaction.currency}`,
          backendType: Constants.BackendType.ZIMPLER,
          state: Constants.TransactionState.WAITING_APPROVAL,
          txType: transaction.txType,
        }),
      transactions
    )
  )
}

/**
 * Fetches user’s pending transactions.
 *
 * @param {Object} params
 * @param {SessionId} params.sessionId
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPendingTransactions(params) {
  const processId = `zimpler/fetch-pending-transactions`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.ZIMPLER,
        requestMethod: 'GET',
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        urlTemplate: '/zimpler/withdrawals',
      }),
      (res) => [
        Actions.updatePendingTransactions(
          transformPendingTransactions(res.value.result.transactions)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePendingTransactions(
            new Error('errors.zimpler.fetch-pending-transactions-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Request cancel user's withdrawal.
 *
 * @param {Object} params
 * @param {SessionId} params.sessionId
 * @param {string} params.transactionId
 * @returns Redux Effects fetch action
 */
export function cancelPendingTransaction(params) {
  const processId = `cancel-pending-transaction-${params.transactionId}`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.ZIMPLER,
        requestData: {
          transactionId: params.transactionId,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        requestMethod: 'DELETE',
        urlTemplate: '/zimpler/withdrawals{/transactionId}',
      }),
      () => [
        Actions.removePendingTransaction(params.transactionId),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Processes.stop(processId),
          Actions.removePendingTransaction(
            new Error('errors.zimpler.cancel-pending-transaction-failure')
          ),
        ]
      }
    ),
  ]
}

/**
 * Start payment transaction
 *
 * @returns Redux Effects action
 */
export function performTransaction() {
  return Actions.startTransaction({
    redirectOutput: {container: 'zimpler'},
    transactionState: Constants.TransactionState.WAITING_INPUT,
  })
}
