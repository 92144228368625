/**
 * @constant
 */
export const CLEARED = 'payments/CLEARED'

/**
 * @constant
 */
export const ACCOUNTS_UPDATED = 'payments/ACCOUNTS_UPDATED'

/**
 * @constant
 */
export const PAYMENT_METHODS_UPDATED = 'payments/PAYMENT_METHODS_UPDATED'

/**
 * @constant
 */
export const TRANSACTION_STARTED = 'payments/TRANSACTION_STARTED'

/**
 * @constant
 */
export const TRANSACTION_UPDATED = 'payments/TRANSACTION_UPDATED'

/**
 * @constant
 */
export const TRANSACTION_COMPLETED = 'payments/TRANSACTION_COMPLETED'

/**
 * @constant
 */
export const TRANSACTION_RESET = 'payments/TRANSACTION_RESET'

/**
 * @constant
 */
export const PENDING_TRANSACTIONS_UPDATED =
  'payments/PENDING_TRANSACTIONS_UPDATED'

/**
 * @constant
 */
export const PENDING_TRANSACTIONS_CLEARED =
  'payments/PENDING_TRANSACTIONS_CLEARED'

/**
 * @constant
 */
export const ACCOUNTS_CLEARED = 'payments/ACCOUNTS_CLEARED'

/**
 * @constant
 */
export const PAYMENT_METHODS_CLEARED = 'payments/PAYMENT_METHODS_CLEARED'

/**
 * @constant
 */
export const PAYMENT_REDIRECT_CLEARED = 'payments/PAYMENT_REDIRECT_CLEARED'

/**
 * @constant
 */
export const PENDING_TRANSACTION_REMOVED =
  'payments/PENDING_TRANSACTION_REMOVED'

/**
 * @constant
 */
export const ACTIVE_TRANSACTION_POPUP_OPENED =
  'payments/ACTIVE_TRANSACTION_POPUP_OPENED'

/**
 * @constant
 */
export const ACTIVE_TRANSACTION_POPUP_CLOSED =
  'payments/ACTIVE_TRANSACTION_POPUP_CLOSED'

/**
 * @constant
 */
export const RECEIPTS_UPDATED = 'payments/RECEIPTS_UPDATED'

/**
 * @constant
 */
export const RECEIPTS_CLEARED = 'payments/RECEIPTS_CLEARED'

/**
 * @constant
 */
export const TOTAL_DEPOSITS_UPDATED = 'payments/TOTAL_DEPOSITS_UPDATED'
