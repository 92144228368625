import * as R from 'ramda'
import * as processes from '@rushplay/processes'
import * as recompose from 'recompose'
import * as selectors from '../../selectors'

import {Receipts as Component} from './receipts'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {fetchReceipts} from '../../fetch-receipts'

function mapStateToProps(state, props) {
  const paymentsState = R.prop(props.mountPoint, state)

  return {
    receipts: selectors.getReceipts(paymentsState),
    loading: processes.isRunning(paymentsState.processes, {
      ids: [`fetch-receipts-${props.currentPage}`],
    }),
    totalPages: selectors.getTotalPages(paymentsState),
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onFetch: () => fetchReceipts(props.currentPage),
    },
    dispatch
  )
}

const connector = R.compose(
  recompose.setPropTypes({
    currentPage: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)

export const Receipts = connector(Component)
