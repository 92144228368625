import * as R from 'ramda'
import * as processes from '@rushplay/processes'
import * as types from './types'
import * as websockets from '@rushplay/websockets'

import {TransactionState} from './constants'
import {combineReducers} from 'redux'

export function activeTransaction(state = {}, action) {
  switch (R.path(['type'], action)) {
    case types.TRANSACTION_STARTED: {
      return R.pathOr({}, ['payload'], action)
    }

    case types.TRANSACTION_UPDATED: {
      if (state.transactionState === action.payload.transactionState) {
        return state
      }

      return R.assoc('transactionState', action.payload.transactionState, state)
    }

    case types.TRANSACTION_COMPLETED: {
      if (state.transactionState === TransactionState.SUCCESSFUL) {
        return state
      }
      if (action.payload instanceof Error) {
        return {
          transactionState: TransactionState.FAILED,
          error: action.payload.message,
        }
      }

      return {
        transactionState: TransactionState.SUCCESSFUL,
      }
    }

    case types.CLEARED:
    case types.TRANSACTION_RESET:
    case websockets.SESSION_EXPIRED: {
      return {}
    }

    default: {
      return state
    }
  }
}

export function paymentMethods(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (R.path(['type'], action)) {
    case types.PAYMENT_METHODS_UPDATED: {
      return R.merge(state, R.indexBy(R.prop('id'), action.payload))
    }

    case types.CLEARED:
    case websockets.SESSION_EXPIRED: {
      return {}
    }

    default: {
      return state
    }
  }
}

function accounts(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (R.path(['type'], action)) {
    case types.ACCOUNTS_UPDATED: {
      return R.merge(state, R.indexBy(R.prop('accountId'), action.payload))
    }

    case types.CLEARED:
    case websockets.SESSION_EXPIRED: {
      return {}
    }

    default: {
      return state
    }
  }
}

function pendingTransactions(state = [], action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (R.path(['type'], action)) {
    case types.PENDING_TRANSACTIONS_UPDATED: {
      return R.uniq(R.concat(state, action.payload))
    }

    case types.PENDING_TRANSACTION_REMOVED: {
      return R.reject(
        (transaction) => transaction.transactionId === action.payload,
        state
      )
    }

    case types.CLEARED:
    case websockets.SESSION_EXPIRED: {
      return []
    }

    default: {
      return state
    }
  }
}

function receipts(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  const payload = action.payload

  switch (R.path(['type'], action)) {
    case types.RECEIPTS_UPDATED:
      return R.pipe(
        // set receipts.currentPage
        R.set(R.lensPath(['currentPage']), payload.meta.page),
        // set receipts.totalPages
        // TODO: ask backend for totalPages
        R.set(R.lensPath(['totalPages']), payload.meta.total_pages),
        // set receipts.items
        R.set(R.lensPath(['transactions']), payload.receipts)
      )(state)

    case types.CLEARED:
    case websockets.SESSION_EXPIRED: {
      return {}
    }

    case types.TOTAL_DEPOSITS_UPDATED:
      return R.merge(state, {
        totalDepositCents: action.payload,
      })

    default: {
      return state
    }
  }
}

export const reducer = combineReducers({
  processes: processes.reducer,
  activeTransaction,
  accounts,
  receipts,
  paymentMethods,
  pendingTransactions,
})
