import * as types from './types'

/**
 * Clear payments state
 *
 * @returns {Object} Redux action
 */
export function clear() {
  return {
    type: types.CLEARED,
  }
}

/**
 * Update payment methods.
 *
 * @param {(Array.<PaymentMethod>|Error)} methods
 * @returns {Object} Redux action
 */
export function updatePaymentMethods(methods) {
  return {
    type: types.PAYMENT_METHODS_UPDATED,
    error: methods instanceof Error,
    payload: methods,
  }
}

/**
 * Update accounts.
 *
 * @param {Array.<Object>} accounts
 * @returns {Object} Redux action
 */
export function updateAccounts(accounts) {
  return {
    type: types.ACCOUNTS_UPDATED,
    payload: accounts,
  }
}

/**
 * Require player to perform additional steps in order to complete transaction.
 *
 * @see {@link https://docs.paymentiq.io/europe/front/general/redirect}
 *
 * @param {Object} responseBody
 * @param {Object} responseBody.redirectOutput Redirect parameters
 * @param {string} responseBody.transactionState Transaction state
 * @param {PaymentMethodId} responseBody.paymentMethodId Payment Method ID
 * @returns {Object} Redux action
 */
export function startTransaction(responseBody) {
  return {
    type: types.TRANSACTION_STARTED,
    payload: {
      redirectOutput: responseBody.redirectOutput,
      transactionId: responseBody.transactionId,
      transactionState: responseBody.transactionState,
      paymentMethodId: responseBody.paymentMethodId,
    },
  }
}

/**
 * Update transaction state
 *
 * @param {Object} payload
 * @param {TransactionState} payload.transactionState
 * @returns {Object} Redux action
 */
export function updateTransaction(payload) {
  return {
    type: types.TRANSACTION_UPDATED,
    payload: {
      transactionState: payload.transactionState,
    },
  }
}

/**
 * Reset transaction state and remove redirect parameters
 *
 * @returns {Object} Redux action
 */
export function resetTransaction() {
  return {
    type: types.TRANSACTION_RESET,
  }
}

/**
 * Completes transaction. Removes redirect parameters from state.
 *
 * @param {(Object|Error)} [payload] Error in case of failed transaction
 * @returns {Object} Redux action
 */
export function completeTransaction(payload) {
  return {
    type: types.TRANSACTION_COMPLETED,
    payload,
  }
}

/**
 * Updates pending transactions.
 *
 * @param {(Array.<Object>|Error)} pendingTransactions Users's pending
 * transactions
 * @returns {Object} Redux action
 */
export function updatePendingTransactions(pendingTransactions) {
  return {
    type: types.PENDING_TRANSACTIONS_UPDATED,
    error: pendingTransactions instanceof Error,
    payload: pendingTransactions,
  }
}

/**
 * Remove a pending transaction from the list of pending transactions.
 *
 * @param {(string|Error)} transactionId ID of the transaction to be removed
 * @returns {Object} Redux action
 */
export function removePendingTransaction(transactionId) {
  return {
    type: types.PENDING_TRANSACTION_REMOVED,
    error: transactionId instanceof Error,
    payload: transactionId,
  }
}

/**
 * Update receipts.
 *
 * @param {(Receipts|Error)} receipts
 */
export function updateReceipts(receipts) {
  return {
    type: types.RECEIPTS_UPDATED,
    error: receipts instanceof Error,
    payload: receipts,
  }
}

/**
 * Update Total Deposits crossbrand
 *
 * @param {number} payload amount in cents
 * @returns {Object} Redux action
 */
export function updateTotalDepositsCents(payload) {
  return {
    type: types.TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}
