import * as PaymentPropTypes from '../../prop-types'
import * as R from 'ramda'
import * as commands from '../../commands'
import * as recompose from 'recompose'
import * as selectors from '../../selectors'

import {PendingTransactions as Component} from './pending-transactions'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

function mapStateToProps(state, props) {
  const paymentsState = R.prop(props.mountPoint, state)

  return {
    pendingTransactions: selectors.getPendingTransactions(paymentsState, props),
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onFetch: () =>
        commands.requestPlayerTransactionsUpdate({
          attributes: props.attributes,
          maxDate: props.maxDate,
          minDate: props.minDate,
          paymentMethodId: props.paymentMethodId,
          states: props.states,
          transactionId: props.transactionId,
          transactionType: props.transactionType,
        }),
    },
    dispatch
  )
}

const connector = R.compose(
  recompose.setPropTypes({
    attributes: PropTypes.object,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    paymentMethodId: PropTypes.string,
    states: PropTypes.arrayOf(PropTypes.string),
    transactionId: PropTypes.string,
    transactionType: PaymentPropTypes.transactionType,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)

export const PendingTransactions = connector(Component)
